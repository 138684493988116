* {
    overscroll-behavior: none;
}

*:focus:not(:focus-visible) {
    outline: none;
}

*:focus-visible {
    @apply ring-lime-500/30 focus:outline-none focus:ring-4;
}

*::selection {
    @apply bg-lime-200 text-zinc-800;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

body {
    margin-bottom: 180px;
}

.disabled {
    pointer-events: none;
}

h1,
h2,
h3,
h4 {
    @apply text-slate-600;
}

h1 {
    @apply py-12 text-center text-3xl;
}

h2 {
    @apply py-5 text-2xl;
}

h3 {
    @apply py-5 text-xl font-semibold;
}

h4 {
    @apply py-5 text-lg font-semibold;
}

.active-page-button {
    @apply bg-[#343450dd]  rounded-full w-[40px] h-[40px] text-zinc-50 font-black pointer-events-none;
}

.button {
    @apply cursor-pointer rounded-md border border-[#444460] bg-[#444460] px-4 py-3 text-base text-zinc-50 outline-none ring-lime-500/30 transition hover:bg-transparent hover:text-[#444460] focus:ring-4;
}

.form-container {
    @apply m-auto flex justify-center sm:rounded-lg bg-zinc-200 p-10 transition;
}

.form-text {
    @apply mb-2 mt-1 w-full rounded bg-zinc-50 p-2 text-base text-slate-600  ring-lime-500/30 focus:bg-white focus:outline-none focus:ring-4;
}

.form-input {
    @apply block w-full appearance-none border-0 border-b border-slate-300 bg-transparent !px-0 !pb-0 !pt-3.5 text-lg text-gray-900 transition focus:border-slate-500 focus:outline-0 focus:ring-0;
}

label {
    @apply text-base font-semibold text-[#343450df];
}

.error {
    @apply relative text-xs text-rose-500 drop-shadow-2xl;
}

.input-error,
.select-error {
    @apply bg-[#fff6f6ee];
}

.montserrat {
    font-family: 'Montserrat', sans-serif;
}

.success-isbn {
    background-color: #7bf7723d;
}

.background-animate {
    background-size: 400%;
    -webkit-animation: animateBackground 0.7s ease infinite;
    -moz-animation: animateBackground 0.7s ease infinite;
    animation: animateBackground 0.7s ease infinite;
}

.background-animate-slow {
    background-size: 400%;
    -webkit-animation: animateBackground 15s ease infinite;
    -moz-animation: animateBackground 15s ease infinite;
    animation: animateBackground 15s ease infinite;
}

@keyframes animateBackground {
    0%,
    100% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
}

.dialog {
    backdrop-filter: blur(5px) brightness(0.9);
    background: rgba(0, 0, 0, 0.6);
    z-index: 50;
    padding: 0 10px;
}

/* Star rating */

.ratingStar {
    @apply leading-8;
}

.rate {
    float: right;
    height: 46px;
    line-height: 2rem;
}
.rate:not(:checked) > input {
    position: absolute;
    left: -9999px;
}
.rate:not(:checked) > label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 35px;
    /* color: #ccc; */
    color: #52525240;
}
.rate:not(:checked) > label:before {
    content: '★';
}
.rate > input:checked ~ label {
    color: #fb7185;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #fda4af;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #fb7185;
}

/* Star Rating pt2*/

:root {
    --star-size: 25px;
    --star-color: #cdcdcd;
    --star-background: #fb7185;
}

.Stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; /* make sure ★ appears correctly */
    line-height: 1;
}

.Stars::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(
        90deg,
        var(--star-background) var(--percent),
        var(--star-color) var(--percent)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.word-wrap {
    overflow-wrap: break-word;
    display: inline-block;
}

/* TOOLTIPS */

.tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    font-size: 0.9rem;
    background-color: #334155;
    color: #f1f5f9;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 35px;
    opacity: 0;
    transition: opacity 0.8s;
}

.tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #334155 transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.fade {
    mask-image: linear-gradient(180deg, #000 70%, transparent);
}
