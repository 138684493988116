@keyframes enter-right {
    0% {
        transform: translateX(100px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes enter-left {
    0% {
        transform: translateX(-100px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes zoomInOut {
    0%,
    100% {
        transform: scale(1);
        transform-origin: center center;
    }
    50% {
        transform: scale(1.4);
        transform-origin: center center;
    }
}

@keyframes traceStroke {
    to {
        stroke-dashoffset: 1;
    }
}

@keyframes fadeIn {
    0% {
        fill-opacity: 0.1;
    }

    30%,
    100% {
        fill-opacity: 1;
    }
}

@keyframes exit-right {
    0% {
        transform: translateX(0);
        fill-opacity: 1;
    }

    100% {
        transform: translateX(100px);
        opacity: 0;
    }
}

@keyframes exit-left {
    0% {
        transform: translateX(0);
        fill-opacity: 1;
    }

    100% {
        transform: translateX(-100px);
        opacity: 0;
    }
}

/* Splash fading out to page */
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.book1,
.book2 {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
}
.book1 {
    animation:
        enter-left 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55),
        traceStroke 0.7s ease-in,
        fadeIn 0.5s ease-in,
        zoomInOut 0.3s ease-in-out,
        exit-right 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-delay: 0s, 0.5s, 1s, 1s, 1.4s;
}

.book2 {
    animation:
        enter-right 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55),
        traceStroke 0.7s ease-in,
        fadeIn 0.5s ease-in,
        zoomInOut 0.3s ease-in-out,
        exit-left 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-delay: 0s, 0.5s, 1s, 1s, 1.4s;
}

/* Splash fading out to page */
.fade-out {
    animation: fadeOut 0.4s ease-out;
    animation-delay: 1.9s;
}
